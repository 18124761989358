<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="panel">
          <header>
            Хүргэлтийн бүсүүд
          </header>
          <div class="mt20">
            <div class="text-right mt20">
              <el-button
                type="default"
                size="small"
                @click="searchOutletDialog = true"
                :disabled="
                  mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                "
                >Хуулах</el-button
              >
              <el-button
                type="default"
                size="small"
                @click="historyOutletDialog = true"
                :disabled="
                  mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                "
                >Түүх харах</el-button
              >
            </div>
            <el-form
              label-position="top"
              size="mini"
              :rules="rules"
              :model="zone_change_form"
              ref="zone"
            >
              <el-form-item label="Тайлбар" prop="change_description">
                <el-input
                  type="textarea"
                  rows="2"
                  v-model="zone_change_form.change_description"
                  :disabled="
                    mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                  "
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
      <el-col :span="16" :offset="0">
        <div class="panel">
          <el-radio-group v-model="hexagon_radio">
            <el-radio label="outlet_location">Харилцагчийн байршил</el-radio>
            <el-radio
              :disabled="mArrayIncludes(role, ['ops'])"
              label="allowed_list"
              >Үндсэн бүс</el-radio
            >
            <el-radio
              :disabled="mArrayIncludes(role, ['ops'])"
              label="blocked_list"
              >Хязгаарласан бүс</el-radio
            >
          </el-radio-group>
          <!-- {{Object.prototype.hasOwnProperty.call(outletData, 'removed_list') ? outletData.removed_list : []}} -->
          <div class="partner-google-map" v-loading="load">
            <!-- @resize="onResizeMap" -->
            <gmap-map
              v-if="outletData"
              ref="map"
              :center="center"
              :zoom="gmapDefaultZoom"
              :options="mapOptions"
              :draggable="
                !mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
              "
              style="width:100%;  height: 100%; margin-top: 5px;"
            >
              <gmap-marker
                :position="outletData.location"
                :clickable="true"
                @position_changed="changeLoc($event)"
                @dragend="onMapMarkerDragEnd($event)"
                :draggable="
                  hexagon_radio === 'outlet_location' &&
                    !mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                "
              />
              <gmap-circle
                :center="outletData.location"
                :radius="50"
                :visible="true"
                :options="{
                  strokeColor: 'red',
                  fillColor: 'white',
                  fillOpacity: 0.2
                }"
              >
              </gmap-circle>
              <gmap-info-window
                :options="{
                  maxWidth: 300,
                  pixelOffset: { width: 0, height: -35 }
                }"
                :position="infoWindow.position"
                :opened="infoWindow.open"
                @closeclick="infoWindow.open = false"
              >
                <div v-html="infoWindow.template"></div>
              </gmap-info-window>
              <div v-if="hexagon_radio === 'outlet_location'">
                <gmap-marker
                  v-for="(item, index) in nearby_outlets"
                  :key="index"
                  :position="item.location"
                  icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                  @click="openInfoWindowTemplate(item)"
                />
              </div>
              <div v-if="hexagon_radio === 'blocked_list'">
                <gmap-polygon
                  v-for="(item, index) in activePolygons"
                  :key="index"
                  :paths="item.points"
                  :options="{
                    fillColor: getPolygonFillColor('blocked_list', item.label),
                    strokeOpacity: 0.2,
                    fillOpacity: getPolygonFillOpacity(
                      'blocked_list',
                      item.label
                    )
                      ? 0.3
                      : 0.1
                  }"
                  @click="polygonClick(item, index)"
                  :editable="item.editable"
                  @paths_changed="updatePolygonPaths($event, index)"
                >
                </gmap-polygon>
              </div>
              <div v-if="hexagon_radio === 'blocked_list'">
                <gmap-polygon
                  v-for="(item, index) in getDefaultHexagonList('blocked_list')"
                  :key="index"
                  :paths="item.points"
                  :options="{
                    fillColor: '#FF0000',
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.5,
                    fillOpacity: 0,
                    clickable: false
                  }"
                >
                </gmap-polygon>
              </div>
              <div v-if="hexagon_radio === 'allowed_list'">
                <gmap-polygon
                  v-for="(item, index) in getDefaultHexagonList('allowed_list')"
                  :key="index"
                  :paths="item.points"
                  :options="{
                    fillColor: getPolygonFillColor('allowed_list', item.label),
                    strokeOpacity: 0.2,
                    fillOpacity: getPolygonFillOpacity(
                      'allowed_list',
                      item.label
                    )
                      ? 0.5
                      : 0.1
                  }"
                  @click="polygonClick(item, index)"
                  :editable="item.editable"
                  @paths_changed="updatePolygonPaths($event, index)"
                >
                  <!-- strokeColor: getRandomColor(), -->
                  <!-- strokeColor: strokeColors[index], -->
                </gmap-polygon>
              </div>
            </gmap-map>
          </div>
          <el-row class="mt20">
            <el-col :span="18" :offset="4">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-button
                    type="success"
                    size="medium"
                    @click="savePolygons"
                    class="full-width"
                    :disabled="
                      mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                    "
                    >Хадгалах</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- Search history -->
    <el-dialog
      title="Бүсийн түүх"
      :visible.sync="historyOutletDialog"
      width="1000px"
    >
      <div>
        <el-row :gutter="20">
          <el-col :span="6">
            <label for="">Өмнөх түүхүүд</label>
            <el-select
              v-model="selectedHistory"
              filterable
              placeholder="Өмнөх түүхүүд"
              class="mt10"
            >
              <el-option
                v-for="(item, index) in polygonHistory"
                :key="index"
                :label="item.created_at"
                :value="index"
              >
              </el-option>
            </el-select>
            <div
              v-if="polygonHistory[selectedHistory]"
              style="max-height: 420px; overflow-y: auto; margin-top: 20px"
            >
              <div>
                <el-button
                  type="danger"
                  block
                  size="mini"
                  @click="deletePolygonHistory"
                  >Түүхийг устгах</el-button
                >
              </div>
              <div
                v-for="(item, index) in polygonHistory[selectedHistory]
                  .hexagon_zone"
                :key="index"
              >
                <!-- <p>
                  <strong>Бүс {{ item.delivery_charge }}₮</strong>
                </p>
                <p>Хүргэлтийн мин: {{ item.deliveryTime }}мин</p>
                <p>Шимтгэлийн хувь: {{ item.commission_percent }}%</p>
                <p>
                  Харилцагч өөрөө:
                  {{ item.is_delivery_by_outlet ? "тийм" : "үгүй" }}
                </p> -->
                <el-divider></el-divider>
              </div>
            </div>
          </el-col>
          <el-col :span="18">
            <el-radio-group v-model="hexagon_history_radio">
              <el-radio label="outlet_location">Харилцагчийн байршил</el-radio>
              <el-radio label="allowed_list">Үндсэн бүс</el-radio>
              <el-radio label="blocked_list">Хязгаарласан бүс</el-radio>
            </el-radio-group>
            <div
              class="partner-google-map"
              v-if="
                polygonHistory[selectedHistory] &&
                  mCheckProperty(
                    polygonHistory[selectedHistory],
                    'hexagon_zone'
                  )
              "
            >
              <gmap-map
                v-if="outletData"
                ref="map"
                :center="center"
                :zoom="gmapDefaultZoom"
                :options="mapOptions"
                :draggable="
                  !mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                "
                style="width:100%;  height: 100%; margin-top: 5px;"
              >
                <gmap-marker
                  :position="outletData.location"
                  :clickable="true"
                  @position_changed="changeLoc($event)"
                  @dragend="onMapMarkerDragEnd($event)"
                  :draggable="
                    hexagon_radio === 'outlet_location' &&
                      !mArrayIncludes(role, ['finance', 'other', 'TL', 'CC'])
                  "
                />
                <gmap-info-window
                  :options="{
                    maxWidth: 300,
                    pixelOffset: { width: 0, height: -35 }
                  }"
                  :position="infoWindow.position"
                  :opened="infoWindow.open"
                  @closeclick="infoWindow.open = false"
                >
                  <div v-html="infoWindow.template"></div>
                </gmap-info-window>
                <div v-if="hexagon_history_radio === 'outlet_location'">
                  <gmap-marker
                    v-for="(item, index) in nearby_outlets"
                    :key="index"
                    :position="item.location"
                    icon="https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                    @click="openInfoWindowTemplate(item)"
                  />
                </div>
                <div v-if="hexagon_history_radio === 'blocked_list'">
                  <gmap-polygon
                    v-for="(item,
                    index) in this.generatePolygonsFromHexagonList(
                      polygonHistory[selectedHistory].hexagon_zone.blocked_list
                    )"
                    :key="index"
                    :paths="item.points"
                    :options="{
                      fillColor: getPolygonFillColor(
                        'blocked_list',
                        item.label
                      ),
                      strokeOpacity: 0.2,
                      fillOpacity: getPolygonFillOpacity(
                        'blocked_list',
                        item.label
                      )
                        ? 0.3
                        : 0.1
                    }"
                    @click="polygonClick(item, index)"
                    :editable="item.editable"
                    @paths_changed="updatePolygonPaths($event, index)"
                  >
                  </gmap-polygon>
                </div>
                <div v-if="hexagon_history_radio === 'blocked_list'">
                  <gmap-polygon
                    v-for="(item,
                    index) in this.generatePolygonsFromHexagonList(
                      polygonHistory[selectedHistory].hexagon_zone.allowed_list
                    )"
                    :key="index"
                    :paths="item.points"
                    :options="{
                      fillColor: '#FF0000',
                      strokeColor: '#FF0000',
                      strokeOpacity: 0.5,
                      fillOpacity: 0,
                      clickable: false
                    }"
                  >
                  </gmap-polygon>
                </div>
                <div v-if="hexagon_history_radio === 'allowed_list'">
                  <gmap-polygon
                    v-for="(item,
                    index) in this.generatePolygonsFromHexagonList(
                      polygonHistory[selectedHistory].hexagon_zone.allowed_list
                    )"
                    :key="index"
                    :paths="item.points"
                    :options="{
                      fillColor: getPolygonFillColor(
                        'allowed_list',
                        item.label
                      ),
                      strokeOpacity: 0.2,
                      fillOpacity: getPolygonFillOpacity(
                        'allowed_list',
                        item.label
                      )
                        ? 0.5
                        : 0.1
                    }"
                    @click="polygonClick(item, index)"
                    :editable="item.editable"
                    @paths_changed="updatePolygonPaths($event, index)"
                  >
                  </gmap-polygon>
                  <gmap-polygon
                    v-for="(item,
                    index) in this.generatePolygonsFromHexagonList(
                      polygonHistory[selectedHistory].hexagon_zone.blocked_list
                    )"
                    :key="index"
                    :paths="item.points"
                    :options="{
                      fillColor: '#FF0000',
                      strokeColor: '#FF0000',
                      strokeOpacity: 0.5,
                      fillOpacity: 0.5,
                      clickable: false
                    }"
                  >
                  </gmap-polygon>
                </div>
              </gmap-map>
            </div>
            <p v-else class="text-center pt50">Түүхээс сонгоно уу</p>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="historyOutletDialog = false" size="small"
          >Cancel</el-button
        >
        <el-button type="success" @click="copyPolygonFromHistory()" size="small"
          >Түүхийг хуулах</el-button
        >
      </span>
    </el-dialog>
    <!-- Search outlets -->
    <el-dialog
      title="Бүс хуулах"
      :visible.sync="searchOutletDialog"
      @open="searchOutlet()"
      width="50%"
    >
      <div>
        <el-input
          v-model="searchText"
          placeholder="Харилцагчийн нэр"
        ></el-input>
        <el-table
          style="width: 100%; height:432px; overflow-y:auto"
          :data="outlets"
          v-loading="outletSearchLoading"
        >
          <el-table-column
            prop="full_name_eng"
            label="Англи нэр"
          ></el-table-column>
          <el-table-column
            prop="outlet_name"
            label="Харилцагчийн нэр"
          ></el-table-column>
          <el-table-column align="right" label="Хуулах">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked"></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="searchOutletDialog = false" size="small"
          >Cancel</el-button
        >
        <el-button type="success" @click="copyPolygon()" size="small"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- Add polygon -->
    <el-dialog title="Бүс нэмэх" :visible.sync="dialogVisible" width="500px">
      <div>
        <el-form
          :model="polygonForm"
          ref="polygonForm"
          :rules="polygonFormRules"
          label-position="top"
          size="mini"
        >
          <el-form-item
            label="Жишээ бүс"
            prop="polygon"
            v-if="polygonForm.action === 'add'"
          >
            <el-select v-model="polygonForm.polygon" style="width: 100%">
              <el-option
                v-for="(item, index) in polygons"
                :key="index"
                :label="item.name"
                :value="item.polygon"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Үнэ" prop="delivery_charge">
            <el-currency-input
              v-model="polygonForm.delivery_charge"
              min="0"
              placeholder="Хүртгэлтийн үнэ"
            ></el-currency-input>
            <!-- <el-input
              v-model="polygonForm.delivery_charge"
              type="number"
              min="0"
              placeholder="Хүртгэлтийн үнэ"
            ></el-input> -->
          </el-form-item>
          <el-form-item label="Хугацаа минутаар" prop="deliveryTime">
            <el-input
              v-model="polygonForm.deliveryTime"
              type="number"
              min="0"
              placeholder="Хүргэлт хийх хугацаа"
            ></el-input>
          </el-form-item>
          <div class="mtb20">
            <el-checkbox v-model="polygonForm.is_delivery_by_outlet"
              >Харилцагч өөрөө хүргэх</el-checkbox
            >
          </div>
          <el-form-item
            label="Commission %"
            v-if="polygonForm.is_delivery_by_outlet"
          >
            <el-input
              type="number"
              placeholder="Commission %"
              v-model="polygonForm.commission_percent"
              min="0"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small"
          >Cancel</el-button
        >
        <el-button
          type="success"
          @click="submitPolygonForm('polygonForm')"
          size="small"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const polygons = require("../assets/polygons.json");
import service from "@/helpers/services.js";
import { getGroups, getUserName } from "../utils/auth";
import { formatPrice, arrayIncludes, checkProperty } from "../helpers/helper";
import ElCurrencyInput from "./ElCurrencyInput";
// eslint-disable-next-line no-unused-vars
import {
  geoToH3,
  h3ToGeoBoundary,
  h3ToChildren,
  h3GetResolution,
  // h3ToParent,
  getDestinationH3IndexFromUnidirectionalEdge,
  getH3UnidirectionalEdgesFromHexagon
} from "h3-js";
import axios from "axios";
export default {
  name: "AddPartnerAddress",
  components: {
    ElCurrencyInput
  },
  async mounted() {
    setTimeout(async () => {
      this.$refs.map.$on("zoom_changed", zoomSize => {
        this.hexagonResourceSize = this.hexagonTable[zoomSize];
      });
    }, 5000);
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      load: false,
      selectedHistory: null,
      polygonHistory: [],
      outletData: null,
      loading: null,
      dialogVisible: false,
      searchOutletDialog: false,
      historyOutletDialog: false,
      polygons: polygons,
      outlets: [],
      searchText: "",
      awaitingSearch: false,
      outletSearchLoading: false,
      hexagonResourceSize: 8,
      gmapDefaultZoom: 13,
      polygonForm: {
        is_delivery_by_outlet: false,
        commission_percent: 0,
        polygon: null,
        delivery_charge: 0,
        deliveryTime: null,
        action: "add",
        id: null
      },
      polygonFormRules: {
        delivery_charge: [
          {
            required: true,
            message: "Хүргэлтийн төлбөрийг оруулна уу",
            trigger: "blur"
          }
        ],
        deliveryTime: [
          {
            required: true,
            message: "Хүргэлтийн хугацааг оруулна уу",
            trigger: "blur"
          }
        ]
      },
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 8,
        maxZoom: 16,
        gestureHandling: "greedy"
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      strokeColors: [
        "#ff0000",
        "#ffa500",
        "#ffff66",
        "#81d8d0",
        "#407294",
        "#420420",
        "#065535",
        "#f6546a",
        "#0e2f44",
        "#660066",
        "#003366"
      ],
      role: [],
      zone_change_form: {
        change_description: ""
      },
      rules: {
        change_description: [
          {
            required: true,
            message: "Зассан шалтгаан оруулна уу",
            trigger: "blur"
          }
        ]
      },
      hexagonTable: {
        "20": 14,
        "19": 13,
        "18": 12,
        "17": 11,
        "16": 10,
        "15": 9,
        "14": 8,
        "13": 7,
        "12": 6,
        "11": 5,
        "10": 4,
        "9": 3,
        "8": 2
      },
      hexagon_radio: "allowed_list",
      hexagon_history_radio: "allowed_list",
      nearby_outlets: [],
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: ""
      }
    };
  },
  created() {
    this.getOutlet();
    this.getOutletPolygonHistory();
  },
  computed: {
    activePolygons: {
      cache: false,
      get() {
        if (this.outletData) {
          if (this.hexagon_radio === "allowed_list") {
            return this.getDefaultHexagonList();
          }
          if (this.hexagon_radio === "blocked_list") {
            let tempData = [];
            this.outletData.hexagon_zone.allowed_list.forEach(el => {
              let hexagons = h3ToChildren(el, this.hexagonResourceSize + 1);
              let tempHexagonList = [];
              hexagons.forEach(hexagon => {
                tempHexagonList = tempHexagonList.concat(
                  this.getNeighbourHexagons(hexagon)
                );
              });
              hexagons = hexagons.concat(tempHexagonList);
              hexagons = [...new Set(hexagons)];
              const calculatedH3 = this.generatePolygonsFromHexagonList(
                hexagons
              );
              tempData = tempData.concat(calculatedH3);
            });
            if (
              checkProperty(this.outletData, "hexagon_zone") &&
              checkProperty(this.outletData.hexagon_zone, "blocked_list")
            ) {
              tempData = tempData.concat(
                this.generatePolygonsFromHexagonList(
                  this.outletData.hexagon_zone.blocked_list
                )
              );
            }
            return tempData;
          }
        }
        return this.getDefaultHexagonList();
      }
    }
  },
  watch: {
    searchText() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.searchOutlet();
          this.awaitingSearch = false;
        }, 1000);
        this.awaitingSearch = true;
      }
    },
    hexagon_radio() {
      if (this.hexagon_radio === "outlet_location") {
        this.getNearbyOutlets(
          this.outletData.location.lat,
          this.outletData.location.lng
        );
      }
      this.$forceUpdate();
    }
  },
  methods: {
    getHexagonAllowedList() {
      this.outletData.hexagon_zone.allowed_list.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        this.hexagonAllowedList.push(temp);
      });
    },
    getNeighbourHexagons(h3) {
      let tempList = [];
      tempList.push(h3);
      const edges = getH3UnidirectionalEdgesFromHexagon(h3);
      edges.forEach(el => {
        const calculatedH3 = getDestinationH3IndexFromUnidirectionalEdge(el);
        tempList.push(calculatedH3);
      });
      return tempList;
    },
    getDefaultHexagonList(from) {
      let tempHexagonList = [];
      if (this.outletData) {
        const outletHexagon = geoToH3(
          this.outletData.location.lat,
          this.outletData.location.lng,
          8
        );
        tempHexagonList = [...this.getNeighbourHexagons(outletHexagon)];
        if (
          checkProperty(this.outletData, "hexagon_zone") &&
          checkProperty(this.outletData.hexagon_zone, "allowed_list")
        ) {
          this.outletData.hexagon_zone.allowed_list.forEach(el => {
            tempHexagonList = tempHexagonList.concat(
              this.getNeighbourHexagons(el)
            );
          });
        }
      }
      if (from === "blocked_list") {
        if (
          checkProperty(this.outletData, "hexagon_zone") &&
          checkProperty(this.outletData.hexagon_zone, "allowed_list")
        ) {
          return this.generatePolygonsFromHexagonList(
            this.outletData.hexagon_zone.allowed_list
          );
        }
      }
      const calculatedH3List = this.generatePolygonsFromHexagonList(
        this.outletData.hexagon_zone.blocked_list
      ).concat(this.generatePolygonsFromHexagonList(tempHexagonList));
      return calculatedH3List;
    },
    polygonClick(item) {
      if (!checkProperty(this.outletData, "hexagon_zone")) {
        this.outletData.hexagon_zone = {
          allowed_list: [],
          blocked_list: []
        };
      }
      if (!checkProperty(this.outletData.hexagon_zone, "allowed_list")) {
        this.outletData.hexagon_zone.allowed_list = [];
      }
      if (!checkProperty(this.outletData.hexagon_zone, "blocked_list")) {
        this.outletData.hexagon_zone.blocked_list = [];
      }
      let currentAllowedList = JSON.parse(
        JSON.stringify(this.outletData.hexagon_zone.allowed_list)
      );
      let currentBlockedList = JSON.parse(
        JSON.stringify(this.outletData.hexagon_zone.blocked_list)
      );
      if (this.hexagon_radio === "allowed_list") {
        if (!currentAllowedList.includes(item.label)) {
          this.outletData.hexagon_zone.allowed_list.push(item.label);
        } else {
          this.outletData.hexagon_zone.allowed_list = currentAllowedList.filter(
            el => el !== item.label
          );
        }
      }
      if (this.hexagon_radio === "blocked_list") {
        if (!currentBlockedList.includes(item.label)) {
          this.outletData.hexagon_zone.blocked_list.push(item.label);
        } else {
          if (
            parseInt(this.hexagonResourceSize + 1) ===
            h3GetResolution(item.label)
          ) {
            this.outletData.hexagon_zone.blocked_list = currentBlockedList.filter(
              el => el !== item.label
            );
          }
        }
      }
      this.$forceUpdate();
    },
    deletePolygonHistory() {
      this.$confirm("Устгахдаа итгэлтэй байна уу?", "Warning", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Болих",
        type: "warning",
        title: "Устгах"
      }).then(() => {
        service
          .deletePolygonHistoryById({
            _id: this.polygonHistory[this.selectedHistory]._id
          })
          .then(response => {
            if (response.status === "success") {
              this.polygonHistory = this.polygonHistory.filter(
                item =>
                  item._id !== this.polygonHistory[this.selectedHistory]._id
              );
              this.selectedHistory = null;
              this.$message({
                type: "success",
                message: "Амжилттай устлаа"
              });
            }
          });
      });
    },
    getOutletPolygonHistory() {
      axios
        .post(`https://rest.toktok.mn/admin/getOutletHexagonHistory`, {
          outlet_id: this.$route.query._id
        })
        .then(response => {
          if (response.data.status === "success") {
            this.polygonHistory = [];
            response.data.response.forEach(el => {
              if (el.location && el.location.split(",").length > 1) {
                el.location = {
                  lat: parseFloat(el.longitude),
                  lng: parseFloat(el.longitude)
                };
                this.polygonHistory.push(el);
              }
            });
          }
        });
    },
    getOutlet() {
      this.openFullScreenLoader(true);
      const payload = {
        includeFields: [
          "hexagon_zone",
          "location",
          "client_category",
          "is_delivery_by_outlet",
          "service_list"
        ]
      };
      service
        .getOutletById(this.$route.query._id, payload)
        .then(outletResponse => {
          this.outletData = outletResponse.data.data;
          this.outletData.outlet_id = this.$route.query._id;
          if (checkProperty(this.outletData, "hexagon_zone")) {
            if (this.outletData.hexagon_zone.allowed_list.length === 0) {
              const outletHexagon = geoToH3(
                parseFloat(outletResponse.data.data.latitude),
                parseFloat(outletResponse.data.data.longitude),
                8
              );
              const tempHexagonList = [
                ...this.getNeighbourHexagons(outletHexagon)
              ];
              this.outletData.hexagon_zone.allowed_list = tempHexagonList;
              this.getNearbyOutlets(
                parseFloat(outletResponse.data.data.latitude),
                parseFloat(outletResponse.data.data.longitude)
              );
            }
          } else {
            this.outletData.hexagon_zone = {
              allowed_list: [],
              blocked_list: []
            };
            if (this.outletData.hexagon_zone.allowed_list.length === 0) {
              const outletHexagon = geoToH3(
                parseFloat(outletResponse.data.data.latitude),
                parseFloat(outletResponse.data.data.longitude),
                8
              );
              const tempHexagonList = [
                ...this.getNeighbourHexagons(outletHexagon)
              ];
              this.outletData.hexagon_zone.allowed_list = tempHexagonList;
              this.getNearbyOutlets(
                parseFloat(outletResponse.data.data.latitude),
                parseFloat(outletResponse.data.data.longitude)
              );
            }
          }
          this.outletData.location = {
            lat: parseFloat(outletResponse.data.data.latitude),
            lng: parseFloat(outletResponse.data.data.longitude)
          };
          this.openFullScreenLoader(false);
          if (this.role.includes("ops")) {
            this.hexagon_radio = "outlet_location";
          }
        });
    },
    searchOutlet() {
      // if (this.searchText.length > 0) {
      this.outletSearchLoading = true;
      const payload = {
        includeFields: [
          "full_name_eng",
          "outlet_name",
          "hexagon_zone",
          "location"
        ],
        is_verified: true,
        pageSize: 10000,
        fromRecord: 0,
        outlet_name: this.searchText
      };
      service.getOutlets(payload).then(data => {
        this.outlets = data.data.data;
        this.outletSearchLoading = false;
      });
      // } else {
      //   this.outlets = [];
      // }
    },
    copyPolygon() {
      this.load = true;
      const tempOutlets = [...this.outlets.filter(item => item.checked)];
      if (!checkProperty(this.outletData, "hexagon_zone")) {
        this.outletData.hexagon_zone = {
          allowed_list: [],
          blocked_list: []
        };
      }
      if (!checkProperty(this.outletData.hexagon_zone, "allowed_list")) {
        this.outletData.hexagon_zone.allowed_list = [];
      }
      if (!checkProperty(this.outletData.hexagon_zone, "blocked_list")) {
        this.outletData.hexagon_zone.blocked_list = [];
      }
      this.outletData.hexagon_zone.allowed_list = [];
      this.outletData.hexagon_zone.blocked_list = [];
      tempOutlets.map(outlet => {
        if (checkProperty(outlet, "hexagon_zone")) {
          if (checkProperty(outlet.hexagon_zone, "allowed_list")) {
            this.outletData.hexagon_zone.allowed_list = this.outletData.hexagon_zone.allowed_list.concat(
              outlet.hexagon_zone.allowed_list
            );
          }
          if (checkProperty(outlet.hexagon_zone, "blocked_list")) {
            this.outletData.hexagon_zone.blocked_list = this.outletData.hexagon_zone.blocked_list.concat(
              outlet.hexagon_zone.blocked_list
            );
          }
        }
      });
      this.outletData.hexagon_zone.blocked_list = this.outletData.hexagon_zone.blocked_list;
      this.outletData.hexagon_zone.allowed_list = this.outletData.hexagon_zone.allowed_list;
      this.outlets = [];
      this.searchText = "";
      this.searchOutletDialog = false;
      this.load = false;
    },
    copyPolygonFromHistory() {
      // eslint-disable-next-line
      this.outletData.polygon = this.polygonHistory[this.selectedHistory].hexagon_zone;
      // eslint-disable-next-line
      this.outletData.location = this.polygonHistory[this.selectedHistory].location;
      this.historyOutletDialog = false;
    },
    addPolygon() {
      this.polygonForm.action = "add";
      this.dialogVisible = true;
    },
    updatePlygon(polygon, index) {
      this.polygonForm.action = "update";
      this.polygonForm.commission_percent = polygon.commission_percent;
      this.polygonForm.is_delivery_by_outlet = polygon.is_delivery_by_outlet;
      this.polygonForm.delivery_charge = parseInt(polygon.delivery_charge);
      this.polygonForm.deliveryTime = polygon.deliveryTime;
      this.dialogVisible = true;
      this.polygonForm.id = index;
    },
    savePolygons() {
      this.$refs.zone.validate(valid => {
        if (valid) {
          getUserName().then(name => {
            let user = name.attributes.email.split("@")[0];
            this.openFullScreenLoader(true);
            let payload = JSON.parse(JSON.stringify(this.outletData));
            // payload.hexagon_zone = {
            //   allowed_list: [],
            //   blocked_list: []
            // }
            payload.user = user;
            payload.desc = this.zone_change_form.change_description;
            service.updateOutletHexagon(payload).then(response => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Амжилттай хадгалагдлаа",
                  type: "success"
                });
                this.openFullScreenLoader(false);
                this.$router.push({
                  path: "partner-timetable",
                  query: { _id: this.$route.query._id }
                });
              } else {
                this.$notify({
                  title: "Алдаатай",
                  message: response.data.message,
                  type: "error"
                });
                this.openFullScreenLoader(false);
              }
            });
          });
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    changeLoc(event) {
      this.outletData.location.lat = event.lat();
      this.outletData.location.lng = event.lng();
      this.outletData.latitude = event.lat();
      this.outletData.longitude = event.lng();
    },
    updatePolygonPaths(event, index) {
      let paths = [];
      for (let i = 0; i < event.getLength(); i++) {
        for (let j = 0; j < event.getAt(i).getLength(); j++) {
          let point = event.getAt(i).getAt(j);
          paths.push({ lat: point.lat(), lng: point.lng() });
        }
      }
      this.outletData.polygon[index].points = paths;
    },
    submitPolygonForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.polygonForm.action === "add") {
            const polygonItem = {};
            let tempPolygon = [];
            this.polygonForm.polygon.map(element => {
              tempPolygon.push({ lat: element[1], lng: element[0] });
            });
            polygonItem.points = tempPolygon;
            polygonItem.isShow = true;
            polygonItem.editable = false;
            polygonItem.deliveryTime = this.polygonForm.deliveryTime;
            polygonItem.delivery_charge = parseInt(
              this.polygonForm.delivery_charge
            );
            polygonItem.infoEditable = false;
            polygonItem.is_delivery_by_outlet = this.polygonForm.is_delivery_by_outlet;
            polygonItem.commission_percent = this.polygonForm
              .is_delivery_by_outlet
              ? parseFloat(this.polygonForm.commission_percent)
              : this.outletData.percent;
            this.outletData.polygon.push(polygonItem);
            this.polygonForm.polygon = null;
            this.polygonForm.deliveryTime = 0;
            this.polygonForm.delivery_charge = 0;
          } else if (this.polygonForm.action === "update") {
            this.outletData.polygon[this.polygonForm.id].isShow = true;
            this.outletData.polygon[this.polygonForm.id].editable = false;
            this.outletData.polygon[
              this.polygonForm.id
            ].deliveryTime = this.polygonForm.deliveryTime;
            this.outletData.polygon[
              this.polygonForm.id
            ].delivery_charge = parseInt(this.polygonForm.delivery_charge);
            this.outletData.polygon[this.polygonForm.id].infoEditable = false;
            this.outletData.polygon[
              this.polygonForm.id
            ].is_delivery_by_outlet = this.polygonForm.is_delivery_by_outlet;
            this.outletData.polygon[
              this.polygonForm.id
            ].commission_percent = this.polygonForm.is_delivery_by_outlet
              ? parseFloat(this.polygonForm.commission_percent)
              : this.outletData.percent;
          }
          this.dialogVisible = false;
        }
      });
    },
    openFullScreenLoader(status) {
      if (status) {
        this.loading = this.$loading({
          lock: true,
          text: "Харилцагчийн мэдээллийг татаж авч байна...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
      } else {
        this.loading.close();
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    deletePolygon(index) {
      this.$confirm("Устгахдаа итгэлтэй байна уу?", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Болих",
        type: "warning"
      }).then(() => {
        this.outletData.polygon.splice(index, 1);
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    },
    mformatPrice(value) {
      return formatPrice(value);
    },
    getRandomColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    },
    getPolygonFillColor(type, h3) {
      if (
        type === "allowed_list" &&
        this.outletData.hexagon_zone.blocked_list.includes(h3)
      ) {
        return "#FF0000";
      }
      if (
        checkProperty(this.outletData, "hexagon_zone") &&
        checkProperty(this.outletData.hexagon_zone, type) &&
        this.outletData.hexagon_zone[type].includes(h3)
      ) {
        return "#000000";
      }
      return "#FFFFFF";
    },
    getPolygonFillOpacity(type, h3) {
      if (
        type === "allowed_list" &&
        this.outletData.hexagon_zone.blocked_list.includes(h3)
      ) {
        return true;
      }
      if (
        checkProperty(this.outletData, "hexagon_zone") &&
        checkProperty(this.outletData.hexagon_zone, type) &&
        this.outletData.hexagon_zone[type].includes(h3)
      ) {
        return true;
      }
      return false;
    },
    generatePolygonsFromHexagonList(hexagonList) {
      hexagonList = [...new Set(hexagonList)];
      const tempData = [];
      hexagonList.forEach(el => {
        const polygon = h3ToGeoBoundary(el);
        let temp = {
          label: el,
          points: []
        };
        polygon.forEach(item => {
          temp.points.push({
            lat: item[0],
            lng: item[1]
          });
        });
        tempData.push(temp);
      });
      return tempData;
    },
    onMapMarkerDragEnd(event) {
      if (this.outletData) {
        const outletHexagon = geoToH3(
          event.latLng.lat(),
          event.latLng.lng(),
          8
        );
        const tempHexagonList = [...this.getNeighbourHexagons(outletHexagon)];
        this.outletData.hexagon_zone.allowed_list = tempHexagonList;
      }
      this.getNearbyOutlets(event.latLng.lat(), event.latLng.lng());
    },
    getNearbyOutlets(lat, lng) {
      axios
        .post(`${process.env.VUE_APP_ADMIN_BASE}/getNearbyOutletById`, {
          lat: lat,
          lon: lng,
          outlet_id: this.outletData.outlet_id
        })
        .then(res => {
          if (res.data.status == "success") {
            this.nearby_outlets = [];
            res.data.data.forEach(el => {
              if (el.location && el.location.split(",").length > 1) {
                el.location = {
                  lat: parseFloat(el.longitude),
                  lng: parseFloat(el.longitude)
                };
                this.nearby_outlets.push(el);
              }
            });
          }
        });
    },
    openInfoWindowTemplate(item) {
      this.infoWindow.position = {
        lat: item.location.lat,
        lng: item.location.lng
      };
      this.infoWindow.template = `<b>${item.outlet_name}</b><br>${item.service}<br>`;
      this.infoWindow.open = true;
    },
    mCheckProperty(obj, prop) {
      return checkProperty(obj, prop);
    }
  }
};
</script>
<style scoped>
.icon {
  cursor: pointer;
}
.active-icon {
  color: #409eff;
}
.disabled-icon {
  color: #c0c4cc;
  cursor: not-allowed;
}
.marker-label {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-family: Roboto, Arial, sans-serif;
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
